import { useQuery } from "@apollo/client";
import React, { useContext, useState } from "react";
import { GET_VENDORS } from "../../../Qurries";

import { _Table, Pagination } from "../../../components/Table";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
function VendorList() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading } = useQuery(GET_VENDORS, {
    variables: {
      page: currentPage,
      limit: 50,
    },
  });

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-4">
      {loading ? (
        <div className="min-h-[80vh] w-full flex justify-center items-center">
          <AiOutlineLoading3Quarters className="text-3xl animate-spin" />
        </div>
      ) : (
        <_Table
          exportBtn={false}
          perPage={false}
          heading={"Vendor List"}
          srNo={false}
          copyContent={[3]}
          footer={
            <div className="flex justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={data?.getMerchantVendor?.totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          }
          data={[
            [
              "Sr.No",
              "Creation Date",
              "Vendor ID",
              "Account holder Name",
              "Status",
            ],
            ...(data?.getMerchantVendor?.vendors.map(
              (Vendor: any, index: number) => [
                <div className="cursor-pointer">{index + 1}</div>,
                <div className=" cursor-pointer">
                  {new Date(Number(Vendor.createdAt)).toDateString()}
                </div>,
                <div className=" cursor-pointer">{Vendor._id}</div>,
                <div className=" cursor-pointer">
                  {Vendor?.bank_details?.account_holder}
                </div>,
                <div

                // className={
                //   "cursor-pointer " +
                //   (Vendor.status.toLowerCase() === "active"
                //     ? " text-green-500"
                //     : Vendor.status.toLowerCase() === "inactive"
                //       ? "text-gray-500"
                //       : Vendor.status.toLowerCase() === "suspended"
                //         ? "text-red-500"
                //         : "")
                // }
                >
                  {Vendor?.status}
                </div>,
              ]
            ) || []),
          ]}
        />
      )}
    </div>
  );
}

export default VendorList;
