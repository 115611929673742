import { Form, Input, Modal, Sidebar, SidebarItem } from "edviron-ui-package";
import React, { useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { RiAccountPinCircleFill } from "react-icons/ri";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  SEND_EDIT_OTP,
  GET_USER,
  UPDATE_MERCHANT_MAIL,
  UPDATE_MERCHANT_PHONE_NUMBER
} from "../../../Qurries";
import { toast } from "react-toastify";
import OTPInput from "../../../components/Otp/OTPInput";
import ConfirmationBox from "../../../components/ConfermationBox";
import { Access } from "../TeamMember/TeamMembers";
import ProfileNav from "./ProfileNav";

const Profile = () => {
  const {
    data: user_data,
    loading: user_loading,
    error: user_error,
    refetch,
  } = useQuery(GET_USER);
  const [name, setName] = useState<any>(null);

  const [email, setEmail] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState<any>(null);

  useEffect(() => {
    if (user_data) {
      setName(user_data?.getMerchantQuery.name);
      setEmail(user_data?.getMerchantQuery.email_id);
      setPhoneNumber(user_data?.getMerchantQuery.phone_number);
    }
  }, [user_data]);

  const [isEdit, setIsEdit] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [phoneOpen, setPhoneOpen] = useState(false);


  const EmailUpdate = ({ refetch, setEmailOpen }: any) => {
    const [updateMerchantMail, { loading, error }] =
      useMutation(UPDATE_MERCHANT_MAIL, { errorPolicy: "ignore" });
    const [sendOtp, { loading: otp_loading, error: otp_error }] =

      useMutation(SEND_EDIT_OTP, { errorPolicy: "ignore" });
    const [otpOpen, setOtpOpen] = useState<any>(false);

    const [updatedEmail, setUpdatedEmail] = useState<any>(email);

    const isValidEmail = (email: string) => {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\+[0-9]+)?$/.test(email);
    };

    return (
      <div>
        <div className="relative w-full mt-[20px]">
          <input
            type="text"
            id="floating_outlined-2"
            className="block px-4 py-1.5 h-[48px] w-full text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
            placeholder=" "
            value={updatedEmail}
            disabled={otpOpen}
            onChange={(e) => {
              setUpdatedEmail(e.target.value);
            }}
          />
          <label
            htmlFor="floating_outlined-2"

            className="bg-[#FFFFFF] absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"

          >
            Email
          </label>
        </div>

        <div className="mt-2 mb-2">
          <button
            className="rounded-[6px] py-[8px] text-white text-[14px] bg-[#6687FF] mt-[10px] w-[109px] mb-[25px]"
            onClick={async () => {
              if (!updatedEmail) {
                toast.error("Email cannot be empty");
                return;
              }

              if (!isValidEmail(updatedEmail)) {
                toast.error("Please enter a valid email");
                return;
              }

              if (updatedEmail === email) {
                toast.error("Please enter different email");
                return;
              }
              setOtpOpen(true);
              const res = await sendOtp({
                variables: {
                  type: "email",
                },
              });

              if (res.data?.sendMerchantEditOtp) {
                toast.success("OTP sent successfully");
              }
            }}
          >
            Update
          </button>
        </div>
        {otpOpen && (
          <OTPInput
            length={6}
            onComplete={async (val: any) => {
              const res = await updateMerchantMail({
                variables: {
                  email: updatedEmail,
                  otp: val,
                },
              });

              if (res?.data.updateMerchantMail) {
                toast.success(res?.data?.updateMerchantMail);
                refetch();
                setEmailOpen(false);
              }
            }}
            reSend={async () => {
              return await sendOtp({
                variables: {
                  type: "email",
                },
              });
            }}
          />
        )}
      </div>
    );
  };

  const PhoneNumberUpdate = ({ refetch, setPhoneOpen }: any) => {
    const [updateMerchantPhoneNumber, { loading, error }] = useMutation(
      UPDATE_MERCHANT_PHONE_NUMBER
    );
    const [sendOtp, { loading: otp_loading, error: otp_error }] =
      useMutation(SEND_EDIT_OTP);

    const [otpOpen, setOtpOpen] = useState<any>(null);
    const [updatedPhoneNumber, setUpdatedPhoneNumber] =
      useState<any>(phoneNumber);

    const isValidPhoneNumber = (phoneNumber: string) => {
      return /^\d{10}$/.test(phoneNumber);
    };

    return (
      <div>
        <div className="relative w-full mt-[20px]">
          <input
            type="text"
            id="floating_outlined-2"
            className="block px-4 py-1.5 h-[48px] w-full text-sm text-gray-900 bg-transparent border-[1.5px] border-[#B8C0CD] rounded-[6px] appearance-none focus:outline-none focus:ring-0 focus:border-[#6687FF] peer"
            placeholder=" "
            value={updatedPhoneNumber}
            disabled={otpOpen}
            onChange={(e) => {
              setUpdatedPhoneNumber(e.target.value);
            }}
          />
          <label
            htmlFor="floating_outlined-2"
            className="bg-[#FFFFFF] absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            Phone Number
          </label>
        </div>

        <div className="mt-2 mb-2">
          <button
            className="rounded-[6px] py-[8px] text-white text-[14px] bg-[#6687FF] mt-[10px] w-[109px] mb-[25px]"
            onClick={async () => {
              if (!updatedPhoneNumber) {
                toast.error("Phone number cannot be empty");
                return;
              }
              if (!isValidPhoneNumber(updatedPhoneNumber)) {
                toast.error("Please enter a valid 10-digit phone number");
                return;
              }
              if (updatedPhoneNumber === phoneNumber) {
                toast.error("Please enter different phone number");
                return;
              }
              setOtpOpen(true);
              const res = await sendOtp({
                variables: {
                  type: "phone",
                },
              });

              if (res.data?.sendMerchantEditOtp) {
                toast.success("OTP sent successfully");
              }
            }}
          >
            Update
          </button>
        </div>

        {otpOpen && (
          <OTPInput
            length={6}
            onComplete={async (val: any) => {
              const res = await updateMerchantPhoneNumber({
                variables: {
                  phone_number: updatedPhoneNumber,
                  otp: val,
                },
              });

              if (res.data?.updateMerchantPhoneNumber) {
                toast.success(res?.data?.updateMerchantPhoneNumber);
                refetch();
                setPhoneOpen(false);
              }
            }}
            reSend={async () => {
              return await sendOtp({
                variables: {
                  type: "email",
                },
              });
            }}
          />
        )}
      </div>
    );

  };


  function capitalizeFirstLetter(str: string) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  return (
    <div className="p-[25px] h-[90%]">
      <div className="bg-[#F6F8FA] w-[100%] h-[100%] p-[40px] rounded-[6px]">
        <Modal
          open={emailOpen}
          setOpen={() => {
            setEmailOpen(false);
            setEmail(user_data?.getMerchantQuery.email_id);
          }}
          title="Edit Email"
        >
          <EmailUpdate refetch={refetch} setEmailOpen={setEmailOpen} />
        </Modal>

        <Modal
          open={phoneOpen}
          setOpen={setPhoneOpen}
          title="Update Phone Number"
        >
          <PhoneNumberUpdate refetch={refetch} setPhoneOpen={setPhoneOpen} />
        </Modal>

        <h2 className="text-edviron_black font-bold">My Account</h2>
        <div className="flex h-full flex-col lg:!flex-row  pt-[40px]">
          <ProfileNav user={user_data?.getMerchantQuery?.role} />
          <div className="flex-1 flex flex-col lg:items-center lg:mt-0 mt-4 relative">
            {isEdit && (
              <div className="w-[90%] mb-[10px]">
                <p className="text-[14px] text-[#0A1]">
                  You can edit the details now. Click on Update to save the
                  changes.
                </p>
              </div>
            )}
            <div className="w-[90%] flex justify-between py-[20px] bg-[#F6F8FA] profile-shadow px-[40px]  rounded-[20px]">
              <div className="relative flex items-center content-center">
                <RiAccountPinCircleFill size={70} />
                <div className="ml-[20px]">

                  <p className="text-[#1B163B] font-[600] text-[14px]">
                    {capitalizeFirstLetter(user_data?.getMerchantQuery.name)}
                  </p>

                  <p className="text-[#717171] text-[12px]">
                    {user_data?.getMerchantQuery.role}
                  </p>
                  <p className="text-[#1B163B] text-[14px]">

                    {user_data?.getMerchantQuery.user || 'N/A'}
                  </p>

                </div>
              </div>
            </div>
            <div className="w-[90%] grid lg:!grid-cols-2 grid-cols-1 py-[20px] px-[40px] bg-[#F6F8FA] profile-shadow rounded-[20px] mt-5">
              <div className="py-[10px]">
                <div className="flex w-[200px] justify-between">
                  <p className="text-[#767676] text-[12px]">Email</p>
                  {user_data?.getMerchantQuery.role === "owner" && (
                    <div
                      onClick={() => {
                        setEmailOpen(true);
                      }}
                    >
                      <FaRegEdit
                        size={12}
                        style={{
                          color: "#717171",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
                <p className="text-[#1B163B] text-[14px]">
                  {user_data?.getMerchantQuery.email_id}
                </p>
              </div>
              <div className="py-[10px]">
                <p className="text-[#767676] text-[12px]">Position</p>
                <p className="text-[#1B163B] text-[14px]">
                  {capitalizeFirstLetter(user_data?.getMerchantQuery.role)}
                </p>
              </div>
              <div className="py-[10px]">
                <div className="flex w-[200px] justify-between">
                  <p className="text-[#767676] text-[12px]">Contact</p>
                  {user_data?.getMerchantQuery.role === "owner" && (
                    <div
                      onClick={() => {
                        setPhoneOpen(true);
                      }}
                    >
                      <FaRegEdit
                        size={12}
                        style={{
                          color: "#717171",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
                <p className="text-[#1B163B] text-[14px]">
                  {user_data?.getMerchantQuery.phone_number || 'N/A'}
                </p>
              </div>
              <div className="py-[10px]">
                <p className="text-[#767676] text-[12px]">
                  Company registered name
                </p>
                <p className="text-[#1B163B] text-[14px]">
                  {user_data?.getMerchantQuery.name}
                </p>
              </div>
            </div>
            <p className="absolute bottom-[0px] text-[10px] text-[#767676]">
              <span>Your personal information is securely guarded and treated with the utmost confidentiality.</span>
              <span className="ml-[40px]">Edviron©2024</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
