/* eslint-disable react/jsx-pascal-case */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  CREATE_MEMBER,
  DELETE_MEMBER,
  GET_ALL_MEMBERS,
  GET_USER,
  UPDATE_ACCESS_LEVEL,
  UPDATE_MEMBER_DETAIL,
  RESET_MAILS,
} from "../../../Qurries";
import { toast } from "react-toastify";
import { _Table } from "../../../components/Table";
import { Link } from "react-router-dom";
import { Input, Modal, Form } from "edviron-ui-package";
import { FaRegEdit } from "react-icons/fa";
import Select from "react-select";
import ConfirmationBox from "../../../components/ConfermationBox";
import OTPInput from "../../../components/Otp/OTPInput";
import { Select as Select_edviron } from "edviron-ui-package";
import ProfileNav from "../Profile/ProfileNav";

export enum Access {
  ADMIN = "admin",
  OWNER = "owner",
}

// const SendResetMail = ({ email, setResetMemberPass }: any) => {

//   const [resetMails, { loading, error }] = useMutation(RESET_MAILS);

//   return (
//     <ConfirmationBox
//       setOpen={setResetMemberPass}
//       funtion={async () => {
//         const res = await resetMails({
//           variables: {
//             email: email
//           },
//         });

//         console.log(res);

//         if (res?.data.resetMails.active) {
//           toast.success("Reset mail sent successfully");
//         }
//       }}
//       confirmationText="Send reset mail"
//       ButtonText="Send"
//     />
//   );
// }

const Action = ({
  data,
  setUpdateMemberOpen,
  setMemberData,
  role,
  setDeleteMemberOpen,
  setMemberUserId,
  setResetMemberMail,
  setResetMemberPass
}: any) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      className="relative cursor-pointer w-2 pl-1"
      onClick={() => setShowOptions(!showOptions)}
    >
      <i className="fa-solid fa-ellipsis-vertical"></i>

      {showOptions && (
        <div
          className={`z-50 flex  flex-col fixed top-[-44px] right-[110px]`}
          onMouseLeave={() => setShowOptions(false)}
        >
          <button
            className={`py-1.5 ${
              Access.OWNER === role ? "bg-white" : "bg-gray-400"
            } w-[100%] rounded-t-md text-xs text-[#1B163B] hover:bg-slate-100  float-right px-6`}
            onClick={() => {
              setUpdateMemberOpen(true);
              setMemberData(data);
            }}
            disabled={Access.OWNER !== role}
          >
            Edit
          </button>
          <button
            className={`py-1.5 ${
              Access.OWNER === role ? "bg-white" : "bg-gray-400"
            } text-xs text-red-400 hover:bg-slate-100  float-right px-6`}
            disabled={Access.OWNER !== role}
            onClick={() => {
              setDeleteMemberOpen(true);
              setMemberUserId(data?._id);
            }}
          >
            Delete
          </button>
          <button
            className={`py-1.5 ${
              Access.OWNER === role ? "bg-white" : "bg-gray-400"
            } text-xs text-[#1B163B] hover:bg-slate-100 shadow rounded-b-md  float-right px-6`}
            disabled={Access.OWNER !== role}
            onClick={() => {
              setResetMemberMail(data?.email);
              setResetMemberPass(true);
            }}
          >
            Reset Password
          </button>
        </div>
      )}
    </div>
  );
};

const UpdateAccess = ({ user_id, refetch, setUpdateAccessShow }: any) => {
  const options = [
    { label: "Admin", value: Access.ADMIN },
  ];

  const [selectedAccess, setSelectedAccess] = useState<any>(null);
  const [updateAccessLevel, { loading, error }] =
    useMutation(UPDATE_ACCESS_LEVEL,{errorPolicy: "ignore"});

  useEffect(() => {
    if (error) toast.error(error.message);
  }, [error]);

  return (
    <div className="flex flex-col items-center w-[100%] pt-[20px]">
      <div className="w-[80%]">
        <Select
          options={options}
          components={{
            IndicatorSeparator: () => null,
          }}
          onChange={(selectedOption: any) => {
            setSelectedAccess(selectedOption.value);
          }}
        />
      </div>
      <button
        className="bg-[#6687FF] text-sm rounded-md text-white float-right px-[30px] py-[8px] mt-[20px]"
        onClick={async () => {
          if (!selectedAccess) {
            toast.error("Please select access");
            return;
          }

          const res = await updateAccessLevel({
            variables: {
              user_id: user_id,
              access: selectedAccess,
            },
          });

          if (res.data.updateMerchantAccessLevel === "Access Level updated") {
            toast.success("Access Level updated");
            refetch();
            setUpdateAccessShow(false);
          }
        }}
      >
        Update Access
      </button>
    </div>
  );
};

const DeleteMember = ({
  user_id,
  refetch,
  setDeleteMemberOpen,
}: any) => {
  const [deleteMember, { loading: delete_loading, error: delete_error }] =
    useMutation(DELETE_MEMBER,{errorPolicy: "ignore"});


  return (
    <ConfirmationBox
      setOpen={setDeleteMemberOpen}
      funtion={async () => {
        const res = await deleteMember({
          variables: {
            user_id: user_id
          },
        });

        if (res?.data.deleteMerchantMember.includes("deleted Successfully")) {
          toast.success("Member deleted successfully");
          refetch();
        }
      }}
      confirmationText="Delete Member"
      ButtonText="Delete"
    />
  );
};


const SendResetMail = ({ email, setResetMemberPass }: any) => {

  const [resetMails, { loading, error }] = useMutation(RESET_MAILS);

  return (
    <ConfirmationBox
      setOpen={setResetMemberPass}
      funtion={async () => {
        const res = await resetMails({
          variables: {
            email: email
          },
        });

        console.log(res);

        if (res?.data?.sendResetPassLinkMerchant?.active) {
          toast.success("Reset mail sent successfully");
        }
      }}
      confirmationText="send reset mail"
      ButtonText="Send"
      isDanger={false}
    />
  );
}

const TeamMember = () => {
  const { data, loading, error, refetch } = useQuery(GET_ALL_MEMBERS);
  const [
    createMember,
    { loading: create_member_loading, error: create_member_error },
  ] = useMutation(CREATE_MEMBER,{errorPolicy: "ignore"});
  const [
    updateMemberDetails,
    { loading: updateMemberDetails_loading, error: updateMemberDetails_error },
  ] = useMutation(UPDATE_MEMBER_DETAIL,{errorPolicy: "ignore"});

  const {
    data: user_data,
    loading: user_login,
    error: user_error,
  } = useQuery(GET_USER);

  useEffect(() => {
    if (error) toast.error(error.message);
  }, [error]);

  useEffect(() => {
    if (create_member_error) setNewMemberOpen(false);
  }, [create_member_error]);

  useEffect(() => {
    if (updateMemberDetails_error)
      toast.error(updateMemberDetails_error.message);
  }, [updateMemberDetails_error]);

  const [newMemberOpen, setNewMemberOpen] = useState(false);
  const [updateMemberOpen, setUpdateMemberOpen] = useState(false);
  const [memberData, setMemberData] = useState<any>(null);
  const [updateAccessShow, setUpdateAccessShow] = useState(false);
  const [memberUserId, setMemberUserId] = useState<any>(null);

  const [deleteMemberOpen, setDeleteMemberOpen] = useState(false);
  const [role, setRole] = useState<any>({ name: "Select role" });
  const [resetMemberPass, setResetMemberPass] = useState(false);
  const [resetMembermail, setResetMemberMail] = useState<any>(null);

  const role_map: any = { Admin: Access.ADMIN};

  function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="p-[25px]">
      <div className="bg-[#F6F8FA] p-[40px] rounded-[6px]">
        <h2 className="text-edviron_black font-bold">My Account</h2>
        <div className="flex h-full flex-col lg:!flex-row  pt-[40px]">
        <Modal
            open={resetMemberPass}
            setOpen={setResetMemberPass}
            title="Reset Password"
          >
            <SendResetMail email={resetMembermail} setResetMemberPass={setResetMemberPass} />
          </Modal>
          <Modal
            open={deleteMemberOpen}
            setOpen={setDeleteMemberOpen}
            className="max-w-2xl"
            title="Delete Member"
          >
            <DeleteMember
              user_id={memberUserId}
              owner_mail={user_data?.getMerchantQuery.email_id}
              refetch={refetch}
              setDeleteMemberOpen={setDeleteMemberOpen}
            />
          </Modal>
          <Modal
            open={updateAccessShow}
            setOpen={setUpdateAccessShow}
            title="Update Access"
          >
            <UpdateAccess
              user_id={memberUserId}
              refetch={refetch}
              setUpdateAccessShow={setUpdateAccessShow}
            />
          </Modal>
          <Modal
            open={updateMemberOpen}
            setOpen={setUpdateMemberOpen}
            title="Update Member Details"
          >
            <Form
              onSubmit={async (data: any) => {
                let temp = { ...memberData };
                setMemberData({ memberData, name: data.name });
                if (data.Name) temp["name"] = data.Name;
                if (data.Email) temp["email"] = data.Email;
                if (data["Phone Number"]) temp["phone_number"] = data["Phone Number"];

                const res = await updateMemberDetails({
                  variables: {
                    name: temp.name,
                    user_id: memberData._id,
                    email: temp.email,
                    phone_number: temp.phone_number,
                  },
                });

                if (res?.data?.updateMerchantMemberDetails) {
                  toast.success(res?.data?.updateMerchantMemberDetails);
                  refetch();
                  setUpdateMemberOpen(false);
                }
              }}
            >
              <Input
                type="text"
                placeholder="Name"
                name="Name"
                value={memberData?.name}
                add_error={() => { }}
                required
              />
              <Input
                type="email"
                placeholder="Email ID"
                name="Email"
                value={memberData?.email}
                add_error={() => { }}
                required
              />
              <Input
                type="number"
                placeholder="Phone No."
                name="Phone Number"
                value={memberData?.phone_number}
                add_error={() => { }}
                required
              />

              <div className="mt-2 mb-2 text-center">
                <button className="py-2 px-16 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-[#6687FF] text-white">
                  Update
                </button>
              </div>
            </Form>
          </Modal>
          <Modal
            open={newMemberOpen}
            setOpen={setNewMemberOpen}
            title="Add New Member"
          >
            <Form
              onSubmit={async (data: any) => {
                if (role.name === "select role") {
                  toast.error("Please select role");
                  return;
                }
                if (data.Password !== data["Confirm Password"]) {
                  toast.error("password doest not match");
                  return;
                }

                const res = await createMember({
                  variables: {
                    name: data.Name,
                    email: data.Email,
                    phone_number: data["Phone Number"],
                    access: role_map[role.name],
                    password: data.Password,
                  },
                });

                if (res?.data?.createMerchantMember) {
                  toast.success(res?.data?.createMerchantMember);
                  refetch();
                  setNewMemberOpen(false);
                }
              }}
            >
              <Input
                type="name"
                placeholder="Name"
                name="Name"
                add_error={() => { }}
                required
              />
              <Input
                type="email"
                placeholder="Email ID"
                name="Email"
                add_error={() => { }}
                required
              />
              <Input
                type="number"
                placeholder="Phone No."
                name="Phone Number"
                add_error={() => { }}
                required
              />
              <Select_edviron
                options={["Admin"].map((val) => {
                  return { name: val };
                })}
                selected={role}
                setSelected={setRole}
                label="Select Role"
              />
              <Input
                type="text"
                placeholder="Password"
                name="Password"
                add_error={() => { }}
                required
              />
              <Input
                type="text"
                placeholder="Confirm Password"
                name="Confirm Password"
                add_error={() => { }}
                required
              />

              <div className="mt-2 mb-2 text-center">
                <button className="py-2 px-16 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-[#6687FF] text-white">
                  Submit
                </button>
              </div>
            </Form>
          </Modal>

          <ProfileNav user={user_data?.getMerchantQuery?.role} />
          <div className="flex-1 flex flex-col items-center">
            {data?.getAllMerchantMembers ? (
              <_Table
                className="bg-[#ffffff]"
                heading={"Your Team"}
                pagination={true}
                searchBox={
                  <div className="flex justify-end items-center gap-x-2 w-full">
                    <div className="flex ml-auto">
                      <button
                        onClick={() => setNewMemberOpen(true)}
                        className={`py-1.5 ${user_data?.getMerchantQuery.role === Access.OWNER
                          ? "bg-[#6687FF]"
                          : "bg-gray-400"
                          } text-sm rounded-md text-white float-right px-6`}
                        disabled={user_data?.getMerchantQuery.role !== Access.OWNER}
                      >
                        + Add New Member
                      </button>
                      {/* <SchoolsUploadViaCsv page={page} /> */}
                    </div>
                  </div>
                }
                data={[
                  [
                    "Name",
                    "Email ID",
                    "Mobile No.",
                    "Level of Access",
                    "Action",
                  ],
                  ...data?.getAllMerchantMembers.map((d: any, i: number) => [
                    <div className="trucate">
                      {d.name}
                    </div>,
                    <div className="truncate">{d.email}</div>,
                    <div>{d.phone_number}</div>,
                    <div className="flex justify-between max-w-[8rem] items-center">
                      <span className="mr-[5px]">{capitalizeFirstLetter(d.access)}</span>
                      {/* <div
                        onClick={() => {
                          setUpdateAccessShow(true);
                          setMemberUserId(d._id);
                        }}
                      >
                        {user_data?.getMerchantQuery.role === Access.OWNER && (
                          <FaRegEdit
                            style={{ color: "#717171", cursor: "pointer" }}
                          />
                        )}
                      </div> */}
                    </div>,
                    <Action
                      data={d}
                      setUpdateMemberOpen={setUpdateMemberOpen}
                      setMemberData={setMemberData}
                      role={user_data?.getMerchantQuery.role}
                      setDeleteMemberOpen={setDeleteMemberOpen}
                      setMemberUserId={setMemberUserId}
                      setResetMemberMail={setResetMemberMail}
                      setResetMemberPass={setResetMemberPass}
                    />,
                  ]),
                ]}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
