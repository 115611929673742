/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState, useContext } from "react";
import { IoSearch } from "react-icons/io5";
import Filters from "./components/Filters";
import { FaX } from "react-icons/fa6";
// import EdvironLogo from "../../../../assets/EdvironLogo.svg";
import { useQuery } from "@apollo/client";
import { _Table } from "../../../components/Table";
import { GET_TRANSACTION_REPORT } from "../../../Qurries";
import { dashboardContext } from "../Dashboard";
import { Link } from "react-router-dom";
import { LiaRupeeSignSolid } from "react-icons/lia";

function Transaction() {
  const [transactionData, setTransactionData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const { setTransaction } = useContext(dashboardContext);
  const [transactionTotal, setTransactionAmount] = useState(0);
  const [orderAmountTotal, setOrderAmountTotal] = useState(0);
  const [dateFilter, setDateFilter] = useState<{
    type: string;
    startDate: string;
    endDate: string;
  }>({
    type: "",
    startDate: new Date(0).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
    endDate: new Date().toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
  });

  const [selectedTime, setSelectedTime] = useState<string>("Date");
  const [selectStatus, setSelectStatus] = useState<any>({
    Success: false,
    Pending: false,
    Failed: false,
  });
  const [transactionReportData, settransactionReportData] = useState<any>([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(""),
      key: "selection",
    },
  ]);

  const payment_method_map: any = {
    credit_card: "Credit Card",
    debit_card: "Debit Card",
    net_banking: "Net Banking",
    upi: "UPI",
    wallet: "Wallet",
    credit_card_emi: "Credit Card EMI",
    debit_card_emi: "Debit Card EMI",
    cardless_emi: "Cardless EMI",
    pay_later: "Pay Later",
  };

  const { data: transactionReport, loading: transactionReportLoading } =
    useQuery(GET_TRANSACTION_REPORT, {
      onCompleted: (data) => {
        const formattedData = data.getMerchantTransactionReport.map(
          (row: any) => ({
            transactionTime: row.createdAt,
            orderID: row.collect_id,
            details: row.details,
            transactionAmount:
              row.transaction_amount === null ? "--" : row.transaction_amount,
            paymentMode:
              row.payment_method === "" || row.payment_method === null
                ? "NA"
                : payment_method_map[row.payment_method],
            orderAmount: row.order_amount === null ? "--" : row.order_amount,
            transactionStatus:
              row.status === null ? "NA" : row.status.toLowerCase(),
            ...row,
          })
        );
        setTransactionData(formattedData);
        settransactionReportData(formattedData);
      },
    });

  const [checkboxFilter, setCheckboxFilter] = useState<any>({
    size: 0,
    status: 0,
    mode: 0,
  });

  useEffect(() => {
    const handleSearchFilter = () => {
      let resultFromSearch: any = [];
      let resultFromTimeSearch: any = [];
      let resultFromCheckBoxFilter: any = [];
      if (searchText !== "") {
        transactionReportData &&
          transactionReportData.forEach((row: any) => {
            for (const key in row) {
              if (
                searchText !== "" &&
                row[key] &&
                row[key]
                  .toString()
                  .toLowerCase()
                  .includes(searchText.toString().toLowerCase())
              ) {
                resultFromSearch.push(row);
                break;
              }
            }
          });
      } else {
        resultFromSearch = transactionReportData;
      }
      const timeSearch = resultFromSearch;
      timeSearch.forEach((row: any) => {
        let dateString = new Date(row["transactionTime"].replace(" ", "T"));
        const dateFormatter = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        const dateFormatted = dateFormatter.format(dateString);
        if (
          dateFormatted >= dateFilter.startDate &&
          dateFormatted <= dateFilter.endDate
        ) {
          resultFromTimeSearch.push(row);
        }
      });

      if (checkboxFilter.size !== 0) {
        const checkboxFiltering = resultFromTimeSearch;
        checkboxFiltering.forEach((row: any) => {
          if (
            (checkboxFilter.status === 0 ||
              (checkboxFilter.status !== 0 &&
                checkboxFilter.hasOwnProperty(
                  row?.["transactionStatus"]?.toLowerCase()
                ))) &&
            (checkboxFilter.mode === 0 ||
              (checkboxFilter.mode !== 0 &&
                checkboxFilter.hasOwnProperty(
                  row?.["paymentMode"]?.toLowerCase()
                )))
          ) {
            resultFromCheckBoxFilter.push(row);
          }
        });
      }

      setTransactionData(
        checkboxFilter.size !== 0
          ? resultFromCheckBoxFilter
          : resultFromTimeSearch
      );
    };

    if (transactionReportData) {
      handleSearchFilter();
    }
  }, [searchText, checkboxFilter, dateFilter, transactionReportData]);

  const handleDeleteTimeFilter = () => {
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(""),
        key: "selection",
      },
    ]);
    setDateFilter({
      type: "",
      startDate: "01/01/1970, 5:30:00 AM",
      endDate: new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    });
    setSelectedTime("Date");
  };

  const handleDeleteCheckBoxFilter = () => {
    setCheckboxFilter({
      size: 0,
      mode: 0,
      status: 0,
    });
    setSelectStatus({ Success: false, Pending: false, Failed: false });
  };

  const { mode, size, status, ...allOtherFilter } = checkboxFilter;
  const handleDeleteStatusFilters = (status: string) => {
    setCheckboxFilter((pre: any) => {
      delete pre[status?.toLowerCase()];
      return { ...pre, size: pre.size - 1, status: pre.status - 1 };
    });
    status = status.charAt(0).toUpperCase() + status.substring(1);
    setSelectStatus((pre: any) => {
      return { ...pre, [status]: false };
    });
  };

  useEffect(() => {
    const amount: any = [];
    const order_amount: any = [];
    transactionData.filter((d: any) => {
      if (d.transactionStatus === "success") {
        amount.push(d.transaction_amount);
        order_amount.push(d.order_amount);
      }
    });

    setOrderAmountTotal(
      order_amount?.reduce((a: any, current: any) => a + current, 0)
    );
    setTransactionAmount(
      amount?.reduce((a: any, current: any) => a + current, 0)
    );
  }, [transactionData]);

  return (
    <div className="mx-4 mt-2 w-full">
      <h2 className="text-[#1B163B] text-[28px] ml-4 font-[600]">
        Transactions
      </h2>

      <div className="w-full  grid grid-cols-2">
        <h2 className="text-[#1B163B] text-[24px] ml-2  font-[400]">
          Transactions Amount
        </h2>
        <h2 className="text-[#1B163B] text-[24px] ml-2  font-[400]">
          Order Amount
        </h2>
        <div className="text-[#229635] font-[400] flex items-center ">
          <span className="text-[44px] flex items-center">
            <LiaRupeeSignSolid />{" "}
            {transactionTotal !== null ? (
              <span>{transactionTotal?.toLocaleString("hi-in")}</span>
            ) : (
              <span>0</span>
            )}
          </span>
          <span className="text-[20px] text-[#717171] flex items-center ml-2">
            {` (selected period )`}
          </span>
        </div>
        <div className="text-[#229635] font-[400] flex items-center ">
          <span className="text-[44px] flex items-center">
            <LiaRupeeSignSolid />
            {orderAmountTotal !== null ? (
              <span>{orderAmountTotal?.toLocaleString("hi-in")}</span>
            ) : (
              <span>0</span>
            )}
          </span>
          <span className="text-[20px] text-[#717171] flex items-center ml-2">
            {` (selected period )`}
          </span>
        </div>
      </div>
      {transactionReportData ? (
        <_Table
          perPage={true}
          exportBtn={true}
          heading={"All Transactions"}
          pagination={true}
          copyContent={[3]}
          filter={[searchText]}
          searchBox={
            <div className="w-full flex flex-col">
              <div className="flex w-full items-center mb-2">
                <div className="bg-[#EEF1F6] py-3 items-center flex  px-6 max-w-md w-full rounded-lg">
                  <i className="text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"></i>
                  <input
                    className="ml-4 text-xs bg-transparent focus:outline-none w-full placeholder:font-normal"
                    onFocus={(e) =>
                      (e.target.style.borderColor = "transparent")
                    }
                    type="text"
                    placeholder=" Search(Order ID...)"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
                <Filters
                  setCheckboxFilter={setCheckboxFilter}
                  checkboxFilter={checkboxFilter}
                  setDateFilter={setDateFilter}
                  setSelectedTime={setSelectedTime}
                  selectedTime={selectedTime}
                  setSelectStatus={setSelectStatus}
                  selectStatus={selectStatus}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  transaction={true}
                />
              </div>
              <div className="flex">
                {dateFilter.type !== "" && (
                  <div className="bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                    <span className="text-white">{dateFilter.type}</span>
                    <FaX
                      onClick={() => handleDeleteTimeFilter()}
                      className="text-white cursor-pointer h-3"
                    />
                  </div>
                )}
                {checkboxFilter.mode === 0 &&
                  Object.keys(allOtherFilter).map((statusFilters: any) => {
                    return (
                      <div className="bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                        <span className="text-white">
                          {/* {Object.keys(allOtherFilter)[0].toUpperCase()} */}
                          {statusFilters}
                        </span>
                        <span>
                          <FaX
                            onClick={() =>
                              handleDeleteStatusFilters(statusFilters)
                            }
                            className="text-white cursor-pointer h-3 "
                          />
                        </span>
                      </div>
                    );
                  })}
                {checkboxFilter.mode !== 0 && (
                  <div className=" bg-[#6687FFCC] text-sm m-2 rounded-lg min-w-max h-10 px-2 flex items-center gap-x-2">
                    <span className="text-white">Custom Filter</span>
                    <span>
                      <FaX
                        onClick={() => handleDeleteCheckBoxFilter()}
                        className="text-white cursor-pointer h-3"
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
          }
          data={[
            [
              "Sr.No",
              "Date & Time",
              "Order ID",
              "Order Amt",
              "Transaction Amt",
              "Payment Method",
              "Status",
              "Student Name",
              "Phone No.",
            ],
            ...transactionData.map((row: any, index: number) => [
              <div>{index + 1}</div>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  className="truncate"
                  key={row.orderID}
                >
                  {new Date(row?.transactionTime).toLocaleString("hi")}
                </div>
              </Link>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  className=" truncate"
                  key={row.orderID}
                >
                  {row.custom_order_id ? row.custom_order_id : row.orderID}
                </div>
              </Link>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  key={row.orderID}
                >
                  {`₹${row.orderAmount}`}
                </div>
              </Link>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  key={row.orderID}
                >
                  {`₹${row.transactionAmount}`}
                </div>
              </Link>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  key={row.orderID}
                >
                  {row.paymentMode}
                </div>
              </Link>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  className={` capitalize ${
                    row.transactionStatus === "success"
                      ? "text-[#04B521]"
                      : row.transactionStatus === "failed"
                      ? "text-[#E54F2F]"
                      : ""
                  }`}
                  key={row.orderID}
                >
                  {row.transactionStatus}
                </div>
              </Link>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  key={row.orderID}
                >
                  {row.student_name ? row.student_name : "NA"}
                </div>
              </Link>,
              <Link to="/payments/transaction-receipt">
                <div
                  onClick={() => {
                    setTransaction(row);
                  }}
                  key={row.orderID}
                >
                  {row.student_phone ? row.student_phone : "NA"}
                </div>
              </Link>,
            ]),
          ]}
        />
      ) : null}
    </div>
  );
}
export default Transaction;
