import React, { useEffect } from "react";

function TrusteeLogin() {
  const urlSearchParms = new URLSearchParams(window.location.search);
  const jwtToken = urlSearchParms.get("token") || "";
  // localStorage.removeItem("merchantToken");
  useEffect(() => {
    if (jwtToken) {
      localStorage.setItem("merchantToken", jwtToken);
    }
  }, [jwtToken]);
  if (jwtToken) return window.location.replace("/");
  return null;
}

export default TrusteeLogin;
