import React from "react";
import { sumTransactionAmountOfToday } from "../../pages/Dashboard/Overview/Helper/filterData";

function Card({ amount, date, description }: any) {
  return (
    <div className="max-h-[170px] min-h-[160px] w-full rounded-[5px] bg-[#F6F8FA] p-5 shadow shadow-[#A9B2CF]">
      <div className="flex items-start justify-between">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#6687FFBF] text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
            />
          </svg>
        </div>
        <h6 className="text-xs font-medium text-[#1B163B]">{date}</h6>
      </div>
      <div className="mt-[23px]">
        <h4 className="text-xs font-light text-[#717171]">{description}</h4>
        <h1 className="text-[22px] font-semibold text-[#1B163B]">{amount}</h1>
      </div>
    </div>
  );
}

export default Card;
