import React, { createContext, useContext, useEffect, useState } from "react";
import { Link, Routes, Route, Navigate, NavLink } from "react-router-dom";
import { Sidebar, SidebarItem } from "../../components/Sidebar";
import { AiFillHome } from "react-icons/ai";
import { BiLogInCircle } from "react-icons/bi";

import ApiKey from "./components/ApiKey/ApiKey";
import { useApolloClient, useQuery } from "@apollo/client";
import Settlement from "./Settlement/Settlement";
import { CiReceipt } from "react-icons/ci";
import { FaCode } from "react-icons/fa6";
import Transaction from "./Transaction/Transaction";
import Navbar from "../../components/Navbar";
import Overview from "./Overview/Overview";
import { FaSchool } from "react-icons/fa6";
import Profile from "./Profile/Profile";
import TeamMember from "./TeamMember/TeamMembers";
import ChangePassword from "./ChangePassword/ChangePassword";

import {
  GET_SETTLEMENT_REPORTS,
  GET_TRANSACTION_REPORT,
  GET_USER,
} from "../../Qurries";
import Payments from "./Payments/Payments";
import TransactionReceipt from "./Transaction/TransactionReceipt";
import Vendor from "./Vendor/Vendor";
export enum Access {
  ADMIN = "admin",
  OWNER = "owner",
}
export const dashboardContext = createContext<any>(null);
function Dashboard({ user, set_user }: any) {
  const [menu, setMenu] = useState(false);
  const [transaction, setTransaction] = useState<any>(null);
  const { data: settlementData } = useQuery(GET_SETTLEMENT_REPORTS);
  const { data: transactionReport, loading: transactionReportLoading } =
    useQuery(GET_TRANSACTION_REPORT);

  const client = useApolloClient();
  const handleLogout = () => {
    localStorage.removeItem("merchantToken");
    set_user(null);
    client.cache.evict({ fieldName: "getSchoolQuery" });
    client.cache.gc();
    client.cache.reset();
  };

  if (!user) return <Navigate to="/login"></Navigate>;

  return (
    <dashboardContext.Provider
      // schoolsData={schoolsData}
      value={{
        user,
        set_user,
        settlementData,
        transactionReport,
        transaction,
        setTransaction,
      }}
    >
      <div className="dashboard flex w-full font-poppins">
        <div className="h-full ">
          <Sidebar
            schoolName={"Edviron"}
            Link={NavLink}
            menu={menu}
            setMenu={setMenu}
          >
            <SidebarItem
              icon={<AiFillHome className={"text-lg"} />}
              name="Home"
              to="/"
            />
            <SidebarItem
              icon={<CiReceipt className={"text-lg"} />}
              name="Payments"
              to="/payments"
            />
            <SidebarItem
              icon={<AiFillHome className={"text-lg"} />}
              name="Vendor"
              to="/vendor"
            />
            <SidebarItem
              icon={<FaCode className={"text-lg"} />}
              name="Developer"
              to="/developer"
            />

            <SidebarItem
              className="mt-auto"
              icon={<BiLogInCircle className={"text-lg"} />}
              name="Log Out"
              onTap={handleLogout}
            />
          </Sidebar>
        </div>

        <div
          className={
            "flex flex-col overflow-hidden flex-1 relative  w-full bg-[#EDF1F4] pl-[5rem]"
          }
        >
          <Navbar logOut={handleLogout} />

          <div className="flex flex-col transition-transform min-h-screen duration-200">
            <div className="flex overflow-hidden flex-col w-full min-h-screen z-10 lg:pr-8 pr-4">
              <Routes>
                <Route path="/" element={<Overview />} />
                <Route
                  path="/developer"
                  element={
                    <div className="w-full mr-6 pl-6">
                      <ApiKey />
                    </div>
                  }
                />
                <Route path="/payments/*" element={<Payments menu={menu} />} />
                <Route path="/vendor/*" element={<Vendor menu={menu} />} />
                <Route path="/" element={<Payments menu={menu} />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/team-members" element={<TeamMember />} />
                <Route
                  path="/payments/transaction-receipt"
                  element={<TransactionReceipt />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </dashboardContext.Provider>
  );
}

export default Dashboard;
