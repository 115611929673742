/* eslint-disable react/jsx-pascal-case */
import React, { useContext } from "react";
import Card from "../../../components/Card/Card";
import LineGraph from "../../../components/Graph/LineGraph";
import { useQuery } from "@apollo/client";
import { GET_TRANSACTION_REPORT } from "../../../Qurries";

import {
  filterTransactionsByMonth,
  filterTransactionsByQuarter,
  getRecentTransactions,
  getSettlementAmount,
  sumTransactionAmountOfToday,
} from "./Helper/filterData";
import { dashboardContext } from "../Dashboard";
import { _Table } from "../../../components/Table";
import { NavLink, useNavigate } from "react-router-dom";
import CopyRight from "../../../components/CopyRight";

function Overview() {
  const { settlementData } = useContext(dashboardContext);
  const { data: transactionReport, loading: transactionReportLoading } =
    useQuery(GET_TRANSACTION_REPORT);
  // const navigate = useNavigate();

  //   const dataByQuarter = filterTransactionsByQuarter(
  //     transactionReport?.getTransactionReport,
  //     2024,
  //     1
  //   );
  //   const dataByMonth = filterTransactionsByMonth(
  //     transactionReport?.getTransactionReport,
  //     2024,
  //     2
  //   );

  //     console.log(
  //       dataByQuarter
  //         .filter((transaction: any) => {
  //           return transaction?.transaction_amount;
  //         })
  //         .reduce((acc: any, curr: any) => acc + curr.transaction_amount, 0)
  //         .toLocaleString("hi")
  //     );
  const transactionReportData = transactionReport?.getMerchantTransactionReport;
  const { sum: sumOfTodaysTransactions, count: countOfTodaysTransactions } =
    sumTransactionAmountOfToday(transactionReportData);
  const settledAmount = getSettlementAmount(
    settlementData?.getMerchantSettlementReports
  );
  const recentTransactions = getRecentTransactions(transactionReportData);
  return (
    <div className="mt-8">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-8 mb-4">
        <Card
          amount={sumOfTodaysTransactions}
          date={"Today"}
          description={"Transaction Amount"}
        />
        <Card
          amount={countOfTodaysTransactions}
          date={"Today"}
          description={"Number of transaction"}
        />
        <Card
          amount={settledAmount}
          date={"Most Recent"}
          description={"Settlement amount"}
        />
      </div>

      <div
        style={{ gridTemplateRows: "max-content" }}
        className="grid grid-cols-3  grid-rows-2 gap-4 mb-4"
      >
        <div className=" lg:col-span-2 order-1 col-span-3 ">
          <LineGraph dataArray={transactionReportData} />
        </div>
        <div className=" lg:col-span-1 lg:order-2 order-3 col-span-3 lg:row-span-2">
          {recentTransactions ? (
            <_Table
              perPage={false}
              exportBtn={false}
              copyContent={[2]}
              bgColor={" bg-transparent"}
              boxPadding={" p-0"}
              copyRight={false}
              description={
                <div className="flex w-full justify-between text-xs pl-4 pr-1">
                  <p className="">Recent transactions</p>
                  <NavLink
                    to="/payments"
                    className="text-[#6687FF] cursor-pointer"
                  >
                    View all
                  </NavLink>
                </div>
              }
              data={[
                ["Date", "Order ID", "Amount"],
                ...recentTransactions?.map((row: any, index: number) => [
                  <div className=" max-w-[5rem]" key={row?.orderID}>
                    {new Date(row?.createdAt).toLocaleString("hi")}
                  </div>,
                  <div
                    className="truncate max-w-[4rem] hover:max-w-fit"
                    title={row?.collect_id}
                  >
                    {row?.collect_id}
                  </div>,
                  <div
                    key={row?.collect_id}
                  >{`₹${row?.transaction_amount}`}</div>,
                ]),
              ]}
            />
          ) : null}
        </div>
      </div>
      <div className="pb-2">
        <CopyRight />
      </div>
    </div>
  );
}

export default Overview;
