import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GET_USER } from "../../../../Qurries";
// import "./api.css";
import { toast } from "react-toastify";
import { FaRegClipboard } from "react-icons/fa6";
import { dashboardContext } from "../../Dashboard";

function ApiKey() {
 
  const [currentApi, SetCurrentApi] = useState("");
  const [isAPIKey, SetIsApiKey] = useState(false);
  const [showReminder, setShowReminder] = useState(false); // State for showing the reminder
  const { user, set_user } = useContext(dashboardContext);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("copied successfully");
      })
      .catch((error) => {
        toast.error("Unable to copy");
      });
  };

  return (
    <div className="p-4  w-full shadow rounded-md mt-[2rem] text-left bg-[#F6F8FA]">
      <p className="mb-2 text-left text-[22px]  text-[#1B163B]">Api Key</p>
      <p className="mb-4 text-base text-[#1B163B]">
        Use API Key to access ERP services
      </p>
      <div className="flex bg-[#EEF1F6] text-[#717171]  text-sm p-4 w-full max-w-lg gap-x-4 shadow rounded-lg mx-auto">
        {/* API Key Display */}
        <div
          style={{
            wordBreak: "break-all",
          }}
          className="max-w-5xl word- px-2"
        >
          {
            // Within the ApiKey component
            isAPIKey
              ? currentApi
              : user && user.getMerchantQuery && user.getMerchantQuery.apiKey
              ? `xxxxxxxxxxxxxxxxxxxxxxxxxx${user.getMerchantQuery.apiKey}`
              : "You don't have any API Key."
          }
        </div>

        {/* Copy to Clipboard button */}
        {isAPIKey && (
          <button
            className=" p-1 z-10 rounded-lg bg-[#d8d8d8]"
            onClick={() => {
              copyToClipboard(currentApi);
            }}
          >
            <FaRegClipboard
              style={{
                fontSize: "30px",
                color: "rgb(67,24,255)",
                backgroundColor: "transparent",
              }}
            />
          </button>
        )}
      </div>


      {/* Reminder */}
      {showReminder && (
        <p className="text-sm mt-2 text-gray-600">
          Save this API key for future use.
        </p>
      )}
    </div>
  );
}

export default ApiKey;
