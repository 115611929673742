import { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import { ToastContainer, toast } from "react-toastify";
import "../node_modules/edviron-ui-package/dist/output.css";
import HomeLoadingScreen from "./components/HomeLoadingScreen.tsx";
import LoadAndRender from "./components/LoadAndRender/LoadAndRender.tsx";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  from,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "react-toastify/dist/ReactToastify.css";
import { onError } from "@apollo/client/link/error";
import { GET_USER } from "./Qurries.ts";
import ResetPassword from "./pages/LoginPage/ResetPassword.tsx";
import TrusteeLogin from "./pages/LoginPage/TrusteeLogin.tsx";
function App() {
  const [user, set_user] = useState(null);

  const { data, loading, refetch } = useQuery(GET_USER, {
    errorPolicy: "ignore",
  });
  useEffect(() => {
    set_user(data);
  }, [data]);

  // if (loading) return <HomeLoadingScreen />;
  return (
    <LoadAndRender
      promise={async () => {
        const token = localStorage.getItem("merchantToken");

        if (token) {
          try {
            const res = await refetch();
            if (res) {
              set_user(res.data);
            } else throw new Error("something wrong");
          } catch (err) {
            console.log(err);
          }
        }
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/login"
            element={<LoginPage user={user} set_user={set_user} />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="*"
            element={<Dashboard user={user} set_user={set_user} />}
          />
          <Route path="/admin/*" element={<TrusteeLogin />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
    </LoadAndRender>
  );
}

export default App;
